/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Harlan
==============================================================================================*/
@import "sass-lib";

@media all and (max-width: 767px) {


/* global
----------------------------------------------------------------------------------------------*/

.after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
.flex-list {display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-flex-flow: row wrap;}


/* structure 
----------------------------------------------------------------------------------------------*/

.wrapper {width: auto; margin: 0 20px;}
a.link_detail {font-size: 14px;}

/* header
----------------------------------------------------------------------------------------------*/
header {
	.wrapper {width: auto; margin: 0 20px;}
	.nav_menu{
		height: 100vh;
		ul{ overflow: auto; height: auto; }
	}
	.logo {
		width: 46px; padding-top: 7px;
	}
}

/* middle
----------------------------------------------------------------------------------------------*/
.txt_top {
    margin: 0 auto 30px;
}
.list3box {
	top: -40px;
	.box {
		width: 135px; margin: 0 5px;
		.txt_abs {
			h3 {font-size: 12px; line-height: 18px;}
		}
	}
}

.top_back {
	a.right {font-size: 14px;}
	.breadcrumb ul li a {font-size: 14px;}
	.navSlider {
		text-align: center;
		.nav h5 {font-size: 14px; line-height: 18px;}
	}
	&.float {margin: 65px 10px 0 10px;}
}
.txt_top {
	width: 380px;
	h6 {font-size: 12px;}
	h2 {font-size: 24px; margin-bottom: 10px; line-height: 30px;}
	p {font-size: 14px; line-height: 18px;}
}

.product_home {padding-bottom: 40px;}
.SlideProd {
	background-position: 50% 50%; position: relative;
	.SlideImg {
		.slick-list {padding: 70px 20px !important;}
		.img.slick-center {@include transform(scale(1.8));}
	}
	.slideKet_img {
		width: 300px; padding: 0 30px;  margin-top: 15px;
		.dec {
			h3 {font-size: 20px;}
			p {font-size: 13px; line-height: 18px;}
		}
		.slick-arrow {
			right: -20px; width: 12px; background-size: 100%; height: 20px;
			&.slick-prev {left: -20px;}
		}
	}
	.wrap_slide {width: auto; margin: 0 20px;}
	.content_slide {
		.img {float: none; width: auto; margin: 0 auto; max-width: 400px;}
		.box_dec {float: none; display: block; width: auto; margin: 0 10px; @include boxSizing(border-box); padding: 20px 15px;}
	}
	.slick-arrow {
		right: 10px; width: 14px; background-size: 100%; height: 24px; top:70px; position: absolute; bottom: unset; top: 320px;
		&.slick-prev {left: 10px;}
	}
}
.home_article {padding: 35px 0 20px;}


.sec_experience {
	.txt_top{width: auto; margin: 0 20px 30px;}
}

.wrap_sec {
	width: auto; margin: 0 25px;
	.rh_wrap {
		.text {
			p {font-size: 14px; line-height: 20px;}
		}
	}
}
.sec_experience {margin-bottom: 30px;}
.slideLabel {
	width: auto;
	.slideImg_logo {
		width: 380px;
		.logoBintang {width: 380px;}
	}
}

.wrap_campaign .wrapper {width: auto; margin: 0 20px;}
.hdline_campaign .big .dec {
	h3 {font-size: 18px; line-height: 24px;}
	p {font-size: 14px; line-height: 22px; -webkit-line-clamp: 3; max-height: 66px;}
}
.list_campaign .box .dec {
	margin: -30px 10px; padding-bottom: 30px;
	h3 {font-size: 13px; line-height: 18px;}
	.tgl {font-size: 14px;}
}

.wrp_detail {
	margin: 0 20px; width: auto;
	.txt_top {
		width: auto; margin: 0 0 30px;
		h2 {margin-bottom: 20px;}
	}
	.list_campaign .box .dec h3 {font-size: 13px; line-height: 18px;}
}
.wrap_campaign.detail .top_back {margin-bottom: 30px;}

footer {
	p {font-size: 14px;}
	.sos {
		float: none; display: block; margin-top: 15px;
		ul {
			li {margin: 0 3px;}
		}
	}
}

.sec_find {
	width: auto; margin: 0 20px 60px;
	> h3 {font-size: 20px;}
}
.list_find .item {max-width: 55%;}

.wrap_event {padding: 65px 0;}

.main_article {
	.filter {
		.col {
			select {font-size: 14px;}
		}
	}
	h3.title {font-size: 16px; line-height: 20px;}
}
.list_article .item {
	.det {
		h3 {font-size: 18px; line-height: 22px;}
		p {font-size: 14px; line-height: 22px;-webkit-line-clamp: 3; max-height: 66px;}
	}
}



.pagging ul {
	li {
		a {font-size: 14px; width: 24px; height: 24px; line-height: 24px;}
		&.prev,
		&.next {
			a {font-size: 14px;}
		}
	}
}

.text_detail {
	 font-size: 14px; line-height: 22px;
	 h1 {font-size: 22px; line-height: 28px;}
	.short {
		p {font-size: 16px; line-height: 22px;}
	}
}

.related {
	h3 {font-size: 18px;}
	.list_related .item h4 {font-size: 14px; line-height: 18px;}
}


.page_screen_age {
	padding: 0; min-height: 480px; height: auto;
	.content_pg_Age {
		width: auto; margin: 0 20px;top: 0; @include transform(translateY(0)); padding: 45px 0;
		.logo_bir {width: 85px;}
		.nav_lang {
			background-size: 20px; padding-left: 30px; padding-right: 5px; margin-bottom: 30px;
			a {font-size: 14px;}
		}
	}
	.drop_content {
		h6 {font-size: 13px; margin-bottom: 12px;}
		h2 {font-size: 26px; margin-bottom: 25PX;}
		form {
			.row {
				.col {
					width: 56px; margin: 0 10px;
					.inp {
						input {font-size: 54px;}
					}
					label {font-size: 14px;}
					&:nth-child(3) {width: 105px;}
				}
			}
		}
		.txt {
			p {font-size: 14px; line-height: 20px;}
		}
		.link {
			.btn {
				font-size: 14px; line-height: 34px; display: block; width: 280px; margin: 0 auto 10px;
				&.fb {}
			}
			> span {margin-bottom: 10px; display: block;}
		}
	}
}


.content-pages {
  ul > {
    li {
      &::before {
        top: 7px; 
      }
      &.small-list {
        li {
          &::before {;
            top: 7px;
          }
        }
      }
    }
  }
}
.popup {
  &__box {
    max-width: none;
    margin-left: 16px;
    margin-right: 16px;
    padding: 45px 24px; 
    font-size: 12px;
    h3 {
      font-size: 20px; 
    }
  }
  &__overflow {
    padding-right: 24px;
    margin-right: -24px;
    max-height: calc(100vh - 150px);
  }
}


} /* end media*/


/* ----------------------------   Media 640   ------------------------------------------------*/



@media all and (max-width: 639px) {


/* global
----------------------------------------------------------------------------------------------*/


.after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
.flex-list {display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-flex-flow: row wrap;}


/* structure 
----------------------------------------------------------------------------------------------*/


/* header
----------------------------------------------------------------------------------------------*/

/* middle
----------------------------------------------------------------------------------------------*/

.home_article {
	.listing {
		display: block;
		.item {
			width: 100%; margin-bottom: 20px;
			h5 {font-size: 24px;}
		}
	}
}


.wrap_sec {
	.lf_wrap,
	.rh_wrap {float: none; display: block; width: auto;}
	.lf_wrap {
		.img,.vid {margin-bottom: 20px;}
	}
}


.SlideProd {
	.content_slide {
		.img {float: none; width: auto; margin: 0 auto 20px; max-width: 340px;}
	}
	.logo_prod img {width: 200px;}
	.pg_slider{ padding-top: 300px; }
}

.top_back .navSlider {
	text-align: left;
	.nav {
		@include boxSizing(border-box); display: block; margin-bottom: 15px; padding: 0; border-left:none; border-right: none;
		&:nth-child(3n) {padding-left: 0; border-left: none;}
	}
}
.list_campaign {
	display: block; margin-bottom: 70px;
	.box {
		width: auto; margin: 0 0 50px;
		.img {
			img {width: 100%; display: block;}
		}
	}
}

.hdline_campaign .big {
	> a {display: block;}
	.img {width: auto; img {display: block;}}
	.dec {width: auto; padding: 18px;}
	.v-center {top: 0; @include transform(translateY(0));}
}

.main_event {
	.main_article {float: none; width: auto;}
	.asside_article {
		float: none; width: auto; margin-top: 30px;
		.boxs {
			h2 {font-size: 20px; line-height: 22px;}
		}
	}
}
/* footer
----------------------------------------------------------------------------------------------*/



} /* end media*/


/* ----------------------------   Media 640   ------------------------------------------------*/



@media all and (max-width: 479px) {


/* global
----------------------------------------------------------------------------------------------*/


.after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
.flex-list {display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-flex-flow: row wrap;}

.SlideProd{
    .SlideImg{
        .img > img{
            @include transform(scale(1));
        }
        .img{
            .anim{
                img {
                    opacity: 1;
                    &.anim_img1 {
                        &.zer {opacity: 1}
                    }
                    &.anim_img2 {
                        &.zer {opacity: 1}
                    }
                    &.anim_img3 {
                       @include transform(scale(1)); opacity: 1; 
                    }
                }
            }
        }
    }
}
/* structure 
----------------------------------------------------------------------------------------------*/



/* header
----------------------------------------------------------------------------------------------*/
header {
	
}


/* middle
----------------------------------------------------------------------------------------------*/
.list3box {
	top: 0; padding-top: 20px; display: block; margin-bottom: 40px;
	.box {
		width: 100%;height: 120px; margin: 0 0 15px;
		img {height: 100%; width: 100%; object-fit: cover;}
		.txt_abs h3 {font-size: 16px; line-height: 22px;}
	}
}
.txt_top {width: auto; margin: 0 10px;}
.SlideProd {
	background-position: 50% 50%;
	.SlideImg {
		.slick-list {padding: 0 20px !important;}
		.img.slick-center {@include transform(scale(1));}
	}
	.slideKet_img {
		width: auto; padding: 0 35px;  margin: 15px 20px;
		.dec {
			h3 {font-size: 20px;}
			p {font-size: 13px; line-height: 18px; margin: 0;}
		}
		.slick-arrow {
			right: -20px; width: 12px; background-size: 100%; height: 20px;
			&.slick-prev {left: -20px;}
		}
	}
	.content_slide .bx_grey ul.col {
		display: block;
		li {width: 100%;}
	}
}
.product_home {padding-bottom: 20px;}

.slideLabel {
	width: auto;
	.slideImg_logo {
		width: auto; margin: 0 20px;
		.logoBintang {width: 300px;}
	}
}
.top_back .navSlider .nav {
	h5 {font-size: 13px;}
}

.list_article .item .img a {height: auto;}


.main_article .filter {
	.col {
		float: none; width: auto; margin-right: 0;
		select {width: 50%; min-width: unset;}
		.select_cus {
			margin-bottom: 20px;
			select {width: 100%; background: none; position: relative; z-index: 2;}
			&:after {width: 35px; border-left: 1px solid #e1232f; background: url('../images/material/arr_select.png') no-repeat center; right: 0; z-index: 1;}
		}
	}
}

/* footer
----------------------------------------------------------------------------------------------*/



} /* end media*/
@media all and (max-width: 380px) {
	.slideLabel {
		width: auto;
		.slideImg_logo {
			width: auto; margin: 0 20px;
			.logoBintang {width: 230px;}
		}
		.dotSlide{ margin: 0 20px; }
	}
	.SlideProd {
		.pg_slider{ padding-top: 250px; }
		.slick-arrow {
			top: 270px;
		}
	}
}